<template>
	<el-form class="page" :model="drzave" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('drzave.naziv')" prop="naziv">
					<el-input v-model="drzave.naziv" :maxLength="100"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('drzave.skraceno')">
					<el-input v-model="drzave.skraceno"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('drzave.oznaka')">
					<el-input v-model="drzave.oznaka"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('drzave.valuta')">
					<select-valute class="full" v-model="drzave.id_valute" ref="valute"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'drzave-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			drzave: {
				naziv: '',
				skraceno: '',
				oznaka: '',
				id_valute: null
			},
			rules: {
				naziv: [{ required: true, max: 100, trigger: 'blur', message: this.$t('message.obavezno') }]
			},
			continentList: ['eu']
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('drzave.drzave')
		});

		this.$utils('stopLoadingAfter', [this.getDrzave()]);
	},
	methods: {
		getDrzave() {
			if (!this.formCreate) {
				return this.$get('drzave', { id: this.id }).then(data => {
					this.drzave = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.drzave);

				if (this.formCreate)
					this.$save('drzave', data);
				else
					this.$update('drzave', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	},
	components: {
		selectValute: require('../../components/select/valute').default
	}
};
</script>
